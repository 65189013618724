
define('tmpl!modules/mobile/templates/vis', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="treemap">\n</div>\n\n<div class="info">\n  This represents close to 100,000 packages.\n</div>\n\n\n';

}
return __p
};});

