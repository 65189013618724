
define('tmpl!modules/mobile/templates/topbar-start', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<a class="header-image" href="/">\n  <object data="/img/logo_mobile.svg" type="image/svg+xml">\n    <img src="/img/logo_mobile.png" />\n  </object>\n</a>\n\n<div class="help">\n  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\n   width="29px" height="29px" viewBox="0 0 29 29" enable-background="new 0 0 29 29" xml:space="preserve">\n<circle fill="#E6E7E8" cx="14.5" cy="14.5" r="14.5"/>\n<g>\n  <path fill="#FFFFFF" d="M12.075,17.585v-0.939c0-0.812,0.173-1.519,0.521-2.12s0.982-1.24,1.904-1.917\n    c0.889-0.635,1.475-1.151,1.758-1.549s0.425-0.842,0.425-1.333c0-0.55-0.203-0.969-0.609-1.257s-0.973-0.432-1.701-0.432\n    c-1.27,0-2.717,0.415-4.342,1.244l-1.384-2.78c1.887-1.058,3.889-1.587,6.005-1.587c1.744,0,3.129,0.419,4.158,1.257\n    s1.542,1.955,1.542,3.352c0,0.931-0.211,1.735-0.635,2.412s-1.227,1.439-2.412,2.285c-0.812,0.601-1.327,1.058-1.542,1.371\n    s-0.324,0.724-0.324,1.231v0.762H12.075z M11.669,21.926c0-0.711,0.19-1.248,0.571-1.611c0.381-0.365,0.935-0.547,1.663-0.547\n    c0.703,0,1.246,0.186,1.631,0.559s0.578,0.906,0.578,1.6c0,0.668-0.195,1.195-0.584,1.58c-0.389,0.387-0.931,0.578-1.625,0.578\n    c-0.711,0-1.261-0.188-1.65-0.564S11.669,22.612,11.669,21.926z"/>\n</g>\n</svg>\n</div>\n';

}
return __p
};});

