
define('tmpl!modules/mobile/templates/06-versions-major1', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="slide slide-3">\n  <h3>NPM By Version Numbers, Cont.</h3>\n\n  <p>\n    Only about <b>3%</b> of packages have actually gone above major version 1.*.\n  </p>\n\n  <p>\n    The higher a package’s major version is, the more likely it is to have more releases: Packages whose major version is equal to or greater than 1.* actually have a higher median value of <b>7</b>, and a mean of <b>13</b> releases. The median number across all packages is <b>3</b>\n  </p>\n</div>';

}
return __p
};});

