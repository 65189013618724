
define('tmpl!modules/mobile/templates/14-dependents-how', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="slide slide-5">\n  <h3>NPM By Dependents</h3>\n\n  <p>\n    We spent some time tracking all the dependencies on every package in NPM. To do this we followed the dependents tree for each package, counting unique dependents.\n  </p>\n  <p>\n    What this means is that if package <b>a</b> has one dependent <b>b</b>, but then there are 100 packages that depend on <b>b</b>, that means <b>a</b> actually has 101 things depending on it! (one directly and 100 indirectly.)\n  </p>\n\n</div>';

}
return __p
};});

