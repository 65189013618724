
define('tmpl!modules/mobile/templates/00-intro', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="slide slide-1">\n  <h3></h3>\n  <a href="http://npmjs.org">NPM</a> (Node Package Manager) is the default package manager for Node.js. As of September 22nd 2014, it has over 99,000 packages hosted, a number that grew by 10,000 packages in the last two months alone.\n  </p>\n  <p>\n  We explore how the NPM ecosystem has evolved across version numbers, dependencies and age.\n  </p>\n\n  <a class="start" href="#">\n    swipe to start &raquo;\n  </a>\n\n  <div class="note">\n    For the full interactive experience, explore this in a desktop or tablet web browser. Seriously. There are things flying all over the place... it\'s a lot more fun =).\n  </div>\n</div>';

}
return __p
};});

