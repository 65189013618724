
define('tmpl!modules/mobile/templates/16-dependents-2', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="slide slide-5">\n  <h3>NPM By Dependents, Cont.</h3>\n\n  <p>There is one caveat.</p>\n  <p>\n    These numbers don\'t account for packages that are frequently depended on\n    in applications that don\'t get pushed back into npm (aka, download data.) For example, <code>express</code> is a commonly used module, but most likey for specific products and applications that are not distributed back via NPM. We don\'t account for these numbers.\n  </p>\n\n</div>';

}
return __p
};});

