
define('tmpl!modules/mobile/templates/10-age', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="slide slide-4">\n  <h3>NPM By Age</h3>\n\n  <p>\n     Packages are being added to npm at a very fast pace.\n  </p>\n\n  <p>\n    <b>In the past year alone, 53,767 packages were added</b>, which is 57% of all packages on npm!\n  </p>\n\n  <p>\n    Regardless of the age, most packages aren\'t past major version 0.*.\n  </p>\n  <p>\n    82% of packages in every age bucket have a major version that is 0.*.\n  </p>\n\n</div>';

}
return __p
};});

