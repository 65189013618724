
define('tmpl!modules/mobile/templates/15-dependents', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="slide slide-5">\n  <h3>NPM By Dependents, Cont.</h3>\n\n  <p>\n      The majority of packages on npm, <b>70,828 (75%), have no packages depending on them at all.\n    </b>\n  </p>\n\n  <p>\n    <b>Only 1,072 (1%) of the package on npm have more than 100 other packages depending on them</b> directly\n    or indirectly (by depending on packages that depend on them etc.)\n  </p>\n  <p>\n    Older packages tend to have more packages depending on them.\n  </p>\n\n</div>';

}
return __p
};});

