
define('tmpl!modules/mobile/layouts/main', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="layout single mobile">\n  <div class="wrapper">\n\n    <!-- top navigation (just help and header) -->\n    <section id="topbar">\n    </section>\n\n    <!-- text content -->\n    <section id="slide-container">\n    </section>\n\n    <!-- silly force layout here -->\n    <section id="force">\n    </section>\n\n    <!-- main visualization area here -->\n    <section id="vis">\n    </section>\n\n    <!-- about details -->\n    <section id="about-info">\n    </section>\n\n    <section id="footer">\n        Made with ♥ at <a href="http://bocoup.com">Bocoup</a> by <a href="http://twitter.com/ireneros">Irene Ros</a>.\n    </section>\n  </div>\n</div>';

}
return __p
};});

