
define('tmpl!modules/mobile/templates/05-versions', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="slide slide-2">\n  <h3>NPM By Version Numbers</h3>\n\n  <p>\n    Packages on npm declare a version for their releases. The version of the package is specified by <a href="http://semver.org/">Semantic Versioning</a>. A version number is written like so: <code>major.minor.patch</code>.\n  </p>\n  <p>\n    A major version increment is a big deal in theory - packages usually increment a major version when when they introduce breaking API changes.\n  </p>\n  <p>\n    <b>Most packages on npm (82%) have a major version of zero (0.*...)</b>.\n  </p>\n\n</div>';

}
return __p
};});

